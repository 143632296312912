import "./shared.scss";
import React, { useEffect } from "react";
import { graphql, StaticQuery } from "gatsby";
import Markdown from "marked-react";
import Header from "../components/Header";
import Footer from "../components/Footer";
import { Helmet } from "react-helmet";

function ObchodniPodminky(data) {
  const pageData = data.data.allStrapiPages.nodes.filter((obj) => {
    return obj.URL === "obchodni-podminky";
  });

  useEffect(() => {
    if (document.title !== pageData[0].Title + " - Vímto") {
      document.title = pageData[0].Title + " - Vímto";
    }
  });

  return (
    <div className="App">
      <Helmet>
        <script
          async
          src="https://www.googletagmanager.com/gtag/js?id=AW-1070604381"
        ></script>
        <script>
          {`window.dataLayer = window.dataLayer || [];
        function gtag(){dataLayer.push(arguments);}
        gtag('js', new Date());
        gtag('config', 'AW-1070604381');`}
        </script>
      </Helmet>
      <Header></Header>
      <div className="App-content">
        <Markdown>{pageData[0].PageText}</Markdown>
      </div>
      <Footer></Footer>
    </div>
  );
}

export default () => (
  <StaticQuery
    query={graphql`
      query ObchodniPodminkyQuery {
        allStrapiPages {
          nodes {
            PageText
            Title
            URL
            id
          }
        }
      }
    `}
    render={(data) => <ObchodniPodminky data={data} />}
  />
);
